<template>
  <div id="RecommendProductList" style="padding: 1rem 0">
    <div v-if="product_list && product_list.length > 0">
      <!-- Mobile -->
      <b-carousel-list
        class="is-hidden-mobile"
        v-model="index"
        :data="product_list"
        :items-to-show="4.2"
      >
        <template slot="item" slot-scope="props">
          <product-card :productItem="props" />
        </template>
      </b-carousel-list>
      <!-- Others -->
      <b-carousel-list
        class="is-hidden-tablet"
        v-model="index"
        :data="product_list"
        :items-to-show="2.2"
      >
        <template slot="item" slot-scope="props">
          <product-card :productItem="props" />
        </template>
      </b-carousel-list>
    </div>
    <div v-else>
      <h1 class="subtitle">No Recommended Products</h1>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductCard from "@/components/cards/ProductCard.vue";

export default {
  name: "RecommendProductList",
  components: {
    ProductCard
  },
  props: ["product_list"],
  data() {
    return {
      index: 0
    };
  }
};
</script>

<style scoped>
.carousel-list.has-shadow {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}
</style>
